import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    // if (!this.props.data) return null;

    // const project = this.props.data.project;
    // const github = this.props.data.github;
    // const name = this.props.data.name;
    // const description = this.props.data.description;

    return (
      <header id="home">
        <ParticlesBg type="circle" bg={true} />

        {/*<nav id="nav-wrap">*/}
        {/*  <a className="mobile-btn" href="#nav-wrap" title="Show navigation">*/}
        {/*    Show navigation*/}
        {/*  </a>*/}
        {/*  <a className="mobile-btn" href="#home" title="Hide navigation">*/}
        {/*    Hide navigation*/}
        {/*  </a>*/}

        {/*  <ul id="nav" className="nav">*/}
        {/*    <li className="current">*/}
        {/*      <a className="smoothscroll" href="#home">*/}
        {/*        Home*/}
        {/*      </a>*/}
        {/*    </li>*/}

        {/*    <li>*/}
        {/*      <a className="smoothscroll" href="#about">*/}
        {/*        About*/}
        {/*      </a>*/}
        {/*    </li>*/}

        {/*    <li>*/}
        {/*      <a className="smoothscroll" href="#resume">*/}
        {/*        Resume*/}
        {/*      </a>*/}
        {/*    </li>*/}

        {/*    <li>*/}
        {/*      <a className="smoothscroll" href="#portfolio">*/}
        {/*        Works*/}
        {/*      </a>*/}
        {/*    </li>*/}

        {/*    <li>*/}
        {/*      <a className="smoothscroll" href="#contact">*/}
        {/*        Contact*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</nav>*/}

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline">Foothill Antibes</h1>
              <h1 className="responsive-headline">Board Candidates</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h2 className="responsive-headline">If you have any questions, please feel free to contact us at</h2>
              <h2 className="responsive-headline"><a href="mailto:candidates@foothillantibes.com">candidates@foothillantibes.com</a></h2>
            </Fade>
            <hr />
            {/*<iframe*/}
            {/*  src="https://docs.google.com/forms/d/e/1FAIpQLSe0i7kIDz6hJrCR2DX9Qb0dyUXdrGJ-VJtkRmFD02DyiM8XAA/viewform?embedded=true"*/}
            {/*  width="640" height="864" frameBorder="0" marginHeight="0" marginWidth="0">Loading...*/}
            {/*</iframe>*/}
            {/*<Fade bottom duration={2000}>*/}
            {/*  <ul className="social">*/}
            {/*    <a href={project} className="button btn project-btn">*/}
            {/*      <i className="fa fa-book"></i>Project*/}
            {/*    </a>*/}
            {/*    <a href={github} className="button btn github-btn">*/}
            {/*      <i className="fa fa-github"></i>Github*/}
            {/*    </a>*/}
            {/*  </ul>*/}
            {/*</Fade>*/}
          </div>
        </div>

        {/*<p className="scrolldown">*/}
        {/*  <a className="smoothscroll" href="#about">*/}
        {/*    <i className="icon-down-circle"></i>*/}
        {/*  </a>*/}
        {/*</p>*/}
      </header>
    );
  }
}

export default Header;
